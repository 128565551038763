import AdminBase from "./Base";
import PocketBase from 'pocketbase';
import React, { useEffect, useState } from 'react';

const PaymentLink = () => {
    const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newLinkCurrency, setNewLinkCurrency] = useState('eur');

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const records = await pb.collection('links').getFullList({
                    sort: '-created',
                });
                setLinks(records);
            } catch (error) {
                console.error("Nepodarilo sa načítať platobné odkazy:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLinks();
    }, [pb]);

    const createNewLink = async () => {
        try {
            const newLink = await pb.collection('links').create({
                currency: newLinkCurrency,
                used: false,
            });
            setLinks(prevLinks => [newLink, ...prevLinks]);
        } catch (error) {
            console.error("Nepodarilo sa vytvoriť nový platobný odkaz:", error);
        }
    };

    return (
        <AdminBase>
            <div className="container mx-auto">
                <h2 className="text-2xl font-bold mb-4">Platobné odkazy</h2>

                <div className="mb-6">
                    <h3 className="text-xl font-semibold mb-2">Vytvoriť nový platobný odkaz</h3>
                    <div className="flex items-center gap-4">
                        <select
                            value={newLinkCurrency}
                            onChange={(e) => setNewLinkCurrency(e.target.value)}
                            className="border border-gray-300 rounded-lg p-2"
                        >
                            <option value="eur">EUR</option>
                            <option value="czk">CZK</option>
                        </select>
                        <button
                            onClick={createNewLink}
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                        >
                            Vytvoriť
                        </button>
                    </div>
                </div>

                {loading ? (
                    <p>Načítavam...</p>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {links.map(link => (
                            <div key={link.id} className="p-4 bg-white shadow-md rounded-lg">
                                <p><strong>ID:</strong> {link.id}</p>
                                <p><strong>Mena:</strong> {link.currency.toUpperCase()}</p>
                                <p><strong>Použité:</strong> {link.used ? 'Áno' : 'Nie'}</p>
                                <div className="flex items-center gap-2 mt-2">
                                    <a
                                        href={`https://sazkovaarena.cz/platba/${link.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:underline"
                                    >
                                        Zobraziť platobný odkaz
                                    </a>
                                    <button
                                        onClick={() => navigator.clipboard.writeText(`https://sazkovaarena.cz/platba/${link.id}`)}
                                        className="text-gray-500 hover:text-gray-700"
                                    >
                                        Kopírovať
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </AdminBase>
    );
};

export default PaymentLink;
