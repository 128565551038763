import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import PocketBase from "pocketbase";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_URL);

const CreateCustomer = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [linkUsed, setLinkUsed] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const verifyLink = async () => {
      try {
        const record = await pb.collection("links").getOne(id);
        if (record.used) {
          setLinkUsed(true);
        } else {
          setLinkUsed(false);
        }
      } catch (err) {
        console.error("Chyba pri overovaní odkazu:", err);
      }
    };

    verifyLink();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const stripeResponse = await axios.post(
        `${process.env.REACT_APP_POCKETBASE_URL}/api/stripe/create-customer`,
        { email, name }
      );
      const customerId = stripeResponse.data.customerId;

      await pb.collection("clients").create({
        name,
        email,
      });

      // Update the link to mark it as used
      await pb.collection("links").update(id, { used: true });

      navigate(`/platba/customer/${customerId}`);
    } catch (err) {
      console.error(err);
      setError("Došlo k chybe pri vytváraní zákazníka. Skúste to znova.");
    } finally {
      setLoading(false);
    }
  };

  if (linkUsed === null) {
    return <p>Overujem platobný odkaz...</p>;
  }

  if (linkUsed) {
    return <p className="text-red-500 text-center">Tento platobný odkaz už bol použitý.</p>;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-6 max-w-md mx-auto bg-white shadow-lg rounded-lg p-6 mt-6"
    >
      <h1 className="text-4xl font-extrabold text-center text-blue-600 mb-4">
        SazkovaArena
      </h1>
      <p className="text-gray-800 text-center text-lg mb-6">
        Objavte <strong>unikátne tipy na výhru</strong>, ktoré vám pomôžu zvýšiť
        vaše šance na úspech!
      </p>

      <div className="bg-blue-100 p-4 rounded-lg text-sm text-blue-800">
        <ul className="list-disc pl-4">
          <li>Exkluzívne tipy od expertov na športové stávky</li>
          <li>Prehľadné a jednoduché odporúčania</li>
          <li>Zaručený spôsob, ako zlepšiť svoje výsledky</li>
        </ul>
      </div>

      {error && (
        <p className="text-red-500 text-center bg-red-100 p-2 rounded">
          {error}
        </p>
      )}

      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
          Meno
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:bg-white py-1"
        />
      </div>

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:bg-white py-1"
        />
      </div>

      <button
        type="submit"
        className="bg-blue-500 text-white w-full px-4 py- rounded-lg font-semibold hover:bg-blue-600 py-2"
        disabled={loading}
      >
        {loading ? "Odosielanie..." : "Pokračovať"}
      </button>

      <div className="mt-6 text-center">
        <p className="text-sm text-gray-600">
          Máte otázky? Kontaktujte nás na{" "}
          <a
            href="mailto:support@sazkovaarena.cz"
            className="text-blue-500 hover:underline"
          >
            support@sazkovaarena.cz
          </a>
          .
        </p>
      </div>
    </form>
  );
};

export default CreateCustomer;